<template>
  <!-- Coming soon page-->

  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">
        Em breve, novidades... 🚀
      </h2>
      <p class="mb-3">
        Estamos desenvolvendo o melhor pensando no seu melhor!
      </p>
      <b-img
        fluid
        :src="imgUrl"
        alt="Coming soon page"
      />
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BImg,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
